@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

input#password1,
input#password2,
input#password3,
input#password4,
input#password5 {
  text-align: center;
}

.text-sm-link-blue {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color:rgb(88 112 181);
}

.mb-10R {
  margin-bottom: 10rem;
}

.mr-10 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 3rem;
}

.mr-11 {
  margin-right: -.9rem;
}

.ml-11 {
  margin-left: -.9rem;
}

.text-base-notifications {
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-align: -webkit-center;
}

.ml-6-notification {
  margin-left: 9rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.bg-white {
  background-color: white !important;
}
.signIn-notes {
  padding: 0 2px 0 5px;
  text-align: left;
}
.mt-20P {
  margin-top: -20px;
}
.ml-20P {
  margin-left: 7px;
  margin-bottom: 25px;
  font-size: 1.3rem !important;
}


.ml-20 {
  margin-left: 3.5rem;
}

.mr-222 {
  margin-right: -1.5rem;
}


.greeting {
  margin-bottom: 5px;
  font-size: 1.3rem !important;
}

.chakra-modal__content-container.css-1oxhx2p {
  top: 20px !important;
}

#main-body > div.chakra-portal > div:nth-child(3) > div {
  z-index: 0;
}

#main-body > div.chakra-portal > div.chakra-modal__overlay.bg-\[\#000\].bg-opacity-50.css-13d1tkw {
  z-index: 0;
}

.list-scroll {
  max-height: 350px;
  overflow: auto;
}

.list-scroll-d {
  max-height: 80vh;
  overflow: auto;
}

.list-border:hover,
.list-border:selected {
  border: 1px solid #000000;
  content: "";
  border-radius: 2%;
}

input:focus {
  outline: none !important;
  border: 1px solid #000000 !important;
  box-shadow: 0 0 10px #2b2b2b !important;
}

input#password1:focus {
  outline: none !important;
  border: 1px solid #f97315 !important;
  box-shadow: 0 0 10px #f97315 !important;
}

.inputWithButton {
  position: relative;
}

.inputWithButton input {
  padding-right: 155px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 10px;
}

.item {
  min-width: 135px; 
  flex: 0 0 auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.custom-max-height {
  max-height: calc(180vh - 6rem);
}

/* Add this CSS to your stylesheet */
.scroll-container {
  max-height: 100%; /* Set maximum height */
  overflow-y: hidden; /* Hide vertical scrollbar */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.drag-handle {
  width: 70px;
  height: 4px;
  background-color: #000;
  border-radius: 2px;
  margin:20px auto 0;
  cursor: pointer;
}

.icon:hover {
  filter: none;
}
.gradient-to-gray-200 {
  background-image: linear-gradient(to bottom right, #ffffff, #ffffff, #eeeeee);
  height: "100vh"
}
/* Add this CSS to your stylesheet */
.gradient-background {
  background: linear-gradient(to bottom right, #eeee, #FAF8F9, #eeee);
}

.custom-sheet-container {
  max-height: 80vh;
}

.custom-width {
  width: 100%;
}

.shadow-bottom {
  /* Adjust the shadow properties as needed */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.custom-border-radius {
  border-radius: 20px;
}

button.disabled {
  background-color: #cccccc; /* Gray background */
  cursor: not-allowed; /* Show disabled cursor */
}

.blurry-background {
  backdrop-filter: blur(6px);
}

.qr-scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Make the container take up the full height of the viewport */
}

/* Style the heading */
.qr-scanner-container h1 {
  margin-bottom: 20px;
}

/* Style the QR reader container */
.react-qr-reader-container {
  width: 90%; /* Adjust width as needed */
  max-width: 500px; /* Set maximum width for the QR reader */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Style the QR reader video element */
.react-qr-reader-container video {
  width: 100%;
  height: auto;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 999; /* Adjust the z-index as needed */
}

.sticky-bottom-user {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* Adjust the z-index as needed */
}

.linkedin-icon {
  color: #223885;
}

.instagram-icon {
  color: #833558;
}

.paper-plane-icon {
  color: #4E3771;
}

.pt-6 {
  padding-top: 5.5rem;
}

.pt-0 {
  padding-top: 1rem;
}
   
.mb-\[70px\] {
  margin-bottom: 40px;
}

.-mb-14 {
  margin-bottom: -1.5rem;
}

.justify-space-between {
  justify-content: space-between;
}

.text-attendee-name {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.w-host {
  width: 7rem;
}

.text-host {
  font-size: .70rem;
  line-height: 1.5rem;
}

.bg-white-gradient {
  background: radial-gradient(circle at 18.7% 37.8%, rgb(253 253 253) 0%, rgb(249 249 249) 90%);
}

.rounded-1 {
  border-radius: 1rem;
}

.text-host {
  font-size: .70rem;
  line-height: 1.5rem;
}

.text-name-700{
--tw-text-opacity: 1;
color: rgb(19 21 23 / 64%);
}

.text-event-name-700{
  --tw-text-opacity: 1;
  color: rgb(19 21 23 / 45%);
  }

.text-event-section{
  --tw-text-opacity: 1;
  color: rgb(19 21 23 / 68%);
  font-weight: 450;
}
.text-gray-gradient{

 color: linear-gradient(45deg,#6e2fe3,#0cabf7,#e27417,#1f6f05);
}

.text-event-footer {
  font-size: 0.85rem;
  line-height: 1.25rem;
  color: rgb(19 21 23 / 60%);
  font-weight: bold;
}

.px-6 {
  padding-right: 2.5rem;
  padding-left: .5rem;

}

.px-7 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-8 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-auto {
  overflow-y: visible;
}

/* Add these styles to your CSS */
.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.sheet-inner-content {
  height: 100%;
  overflow-y: auto;
  padding-right: 16px; /* Adjust as per scrollbar width */
  box-sizing: content-box;
}
